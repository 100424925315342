<template>
  <div class="form">
    <div v-for="(rank, i) in editableRanks" :key="i" class="row">
      <Input class="name" title="Nom" v-model="rank.name" />
      <Input title="Etoiles" v-model="rank.stars" :isDisabled="i === 0 ? true : false" />
      <InputFile
        title="Icône"
        type="image"
        v-model="rank.image"
        :preview="rank.icon.url"
      />
    </div>

    <div class="row buttons-container">
      <Button
        class="save"
        title="Sauvegarder les modifications"
        @click="save"
        type="primary"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash"
import Input from "@/components/Forms/Fields/Input"
import InputFile from "@/components/Forms/Fields/InputFile"
import Button from "@/components/Button"

export default {
  name: "RankForm",
  props: ["ranks"],
  components: {
    Input,
    InputFile,
    Button
  },
  data() {
    return {
      editableRanks: []
    }
  },
  methods: {
    async save() {
      const obj = this.editableRanks.map((el) => {
        return {
          stars: el.stars,
          name: el.name,
          iconId: el.image ? el.image : el.iconId,
          id: el.id
        }
      })
      try {
        await this.$store.dispatch("admin/editRanks", { ranks: obj })
        await this.$store.dispatch("admin/getRanks")
      } catch (e) {
        console.error(e)
      }
    }
  },
  created() {
    this.editableRanks = _.cloneDeep(this.ranks)
  },
  watch: {
    ranks() {
      this.editableRanks = _.cloneDeep(this.ranks)
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  border-bottom: 1px solid $light-grey-color;
  padding: 10px 0;
  margin-top: 20px;

  .row {
    align-items: flex-start;

    .name {
      flex: 1;
    }
  }

  .buttons-container {
    justify-content: center;
    margin: 20px 0;

    div {
      flex: 0;
    }
  }
}
</style>
