<template>
  <AdminView>
    <h1 class="title-1">Rangs</h1>

    <div class="column">
      <RankForm :ranks="ranks" />
    </div>
  </AdminView>
</template>
²

<script>
import { mapGetters } from "vuex"
import AdminView from "@/components/AdminView"
import RankForm from "@/components/Forms/RankForm"

export default {
  name: "Ranks",
  components: {
    AdminView,
    RankForm
  },
  async created() {
    await this.$store.dispatch("admin/getRanks")
  },
  computed: {
    ...mapGetters({
      ranks: "admin/ranks"
    })
  }
}
</script>

<style lang="scss" scoped></style>
